// import { createClass } from 'asteroid';
// const Asteroid = createClass();
import asteroid from "./init";
import dynamicReducer from "./dataReducer";
// import {getCookie, eraseCookie, setCookie} from "./utils"
import { config } from "../config";
import worker from "./worker.js";
import WebWorker from "./WebWorker";
import { BlueberryData } from "./BlueberryData";

class blue {
	constructor(isServer = true) {
		this.isServer = isServer;
		// const asteroid = new asteroid(typeof window === 'undefined')
		this.asteroid = asteroid._init();
		this.user = {};
		this.isLoggedIn = false;
		this.menus = {};
		this.lang = "";
		this.lastKnownProduct = "";
		this.callbacks = {};
		this.worker = null;
		const self = this;
		setTimeout(function () {
			self.resumeLogin().catch(() => {});
		}, 50);

		if (!this.isServer) {
			this.asteroid.on("login", this.onLogin);
			this.asteroid.on("loginResume", this.onLogin);

			this.worker = new WebWorker(worker);
			this.worker.onmessage = (event) => {
				const data = event.data.data;
				// set the calculations to the state when there is a change
				switch (event.data.do) {
					case "total":
						const { itemsMapped } = event.data.data;

						dynamicReducer.cartMap({
							fields: itemsMapped,
						});

						break;
				}
			};
		}
	}

	getAnalyticsId() {
		return typeof analytics !== "undefined" &&
			typeof window !== "undefined" &&
			typeof analytics.user !== "undefined"
			? analytics.user().anonymousId()
			: null;
	}

	initData(initialData) {
		dynamicReducer.initialize(initialData);
	}

	getData(initialData) {
		return JSON.stringify(dynamicReducer.getData());
	}

	dataClone = {};

	setServer() {
		this.dataClone = JSON.stringify(BlueberryData);
	}

	serverDone() {
		const clone = JSON.parse(this.dataClone);
		for (const key in clone) {
			BlueberryData[key] = clone[key];
		}
	}

	setLang(lang) {
		this.lang = lang;
	}

	updateStats(carts) {
		this.worker.postMessage({
			do: "total",
			carts: carts != null ? carts.carts : [],
		});

		dynamicReducer.cart({ fields: carts ? carts.carts : [] });

		dynamicReducer.stats({
			fields: {
				count: carts ? carts.totalItems : 0,
				totalPackage: carts ? carts.totalPackage : 0,
				total: carts ? carts.total : 0,
				cbm: carts ? carts.totalCubicaje : 0,
			},
		});
	}

	login({ email, password, hasLink, token }, retries = 2) {
		const self = this;
		return new Promise(function (resolve, reject) {
			self.asteroid
				.login({ password, user: { email }, hasLink, token })
				.then((userId) => {
					self.assignCartToUser();
					self.getWishlists();
					resolve(userId);
				})
				.catch((error) => {
					if (retries > 0) {
						resolve(
							self.login(
								{ email, password, hasLink, token },
								retries - 1,
							),
						);
					} else {
						reject(error);
					}
				});
		});
	}

	requestMagicLink({ email, isWebsite = true }) {
		const self = this;
		return new Promise(function (resolve, reject) {
			self.asteroid
				.call("login.requestLink", { email, isWebsite })
				.then((message) => {
					resolve(message);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	onLogin = (userId) => {
		localStorage.setItem("ntx-userId", userId.token);

		const self = this;
		(async () => {
			this.asteroid
				.call("users.getInfo")
				.then((user) => {
					if (user) {
						window.localStorage.setItem(
							"user-viewed-notification",
							null,
						);
						self.getWishlists();
						this.getCartAndValidate();
						dynamicReducer.user({
							fields: { ...user, id: user._id },
						});
						if (typeof analytics !== "undefined") {
							var connection = navigator
								? navigator.connection ||
								  navigator.mozConnection ||
								  navigator.webkitConnection
								: { type: "UNKNOWN" };

							var type = connection ? connection.type : "unknown";

							analytics.identify(user._id ? user._id : user.id, {
								name: `${user.profile.firstName} ${user.profile.lastName}`,
								email: user.emails[0].address,
								isDigitalClient: user.isDigitalClient,
								clientSegment: user.clientSegment,
								clientNumber: user.clientNumber,
								clientMarketSegment: user.clientMarketSegment,
								connectionType: type,
								isStandalone:
									typeof window !== "undefined" &&
									window.env &&
									window.env.isStandalone
										? window.env.isStandalone
										: false,
							});
						}

						if (typeof window !== "undefined") {
							window.LC_API = window.LC_API || {};
							window.LC_API.on_before_load = function () {
								window.LC_API.set_visitor_name(
									`${user.profile.firstName} ${user.profile.lastName}`,
								);
								window.LC_API.set_visitor_email(
									user.emails[0].address,
								);
							};
						}
					}
				})
				.catch((err) => {
					console.log(err);
				});

			if (config.IS_NORITEX) {
				self.credit({
					companyId: config.DEFAULT_COMPANY_ID,
					typeOfRequest: "crmByGroup",
				}).catch(() => {});
			}
		})();
	};

	changePassword({ oldPassword, password }) {
		const self = this;
		return new Promise(function (resolve, reject) {
			self.asteroid
				.call("users.changePassword", { oldPassword, password })
				.then(() => {
					resolve();
				})
				.catch((error) => {
					console.log("errors with login");
					reject(error);
				});
		});
	}

	logout() {
		const self = this;
		return new Promise(function (resolve, reject) {
			if (!self.asteroid) resolve("");
			self.asteroid
				.logout()
				.then((userId) => {
					window.localStorage.removeItem(
						"user-viewed-notification",
						null,
					);
					// setCookie("ntx-userId", null)
					localStorage.removeItem("ntx-userId", null);
					localStorage.removeItem("ntx-cookie_token", null);

					dynamicReducer.user({ fields: null });
					dynamicReducer.cart({ fields: null });
					if (self.worker)
						self.worker.postMessage({ do: "total", items: [] });
					resolve("success");
				})
				.catch((error) => {
					console.log("errors with login");
					reject(error);
				});
		});
	}

	resumeLogin = () => {
		// const cookie = getCookie("ntx-userId");
		let cookie = null;
		if (!this.isServer) cookie = localStorage.getItem("ntx-userId");

		return new Promise((resolve, reject) => {
			if (this.isServer) {
				reject();
				return;
			}
			if (cookie != null && cookie !== "") {
				this.asteroid
					.login({ resume: cookie })
					.then(() => {
						resolve();
					})
					.catch((error) => {
						reject();
					});
			} else {
				reject();
			}
		});
	};

	forgotPassword = ({ email }) => {
		// account.forgotPassword
		return this.asteroid.call("forgotPassword", { email });
	};

	resetPassword = ({ token, password }) => {
		// account.forgotPassword
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("resetPassword", token, password)
				.then((resp) => {
					this.onLogin(resp);
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	acceptInvite = ({ token, password }) => {
		// account.forgotPassword
		return this.asteroid.call("acceptInvitation", { token, password });
	};

	getUsersInfoForStore({ brandSlug }) {
		return this.asteroid.call("users.getInfoForStore", {
			brandSlug,
			public_key: config.public_key,
		});
	}

	menu(slug, validatePermissions, retries = 2) {
		// this.callbacks["menu"] = callback
		// this.asteroid.subscribe("menu",{slug: slug });
		// if (!this.isServer) this.asteroid.subscribe("menu", {slug: slug })
		if (this.isServer) new Promise.resolve();

		return new Promise((resolve, reject) => {
			this.asteroid
				.call("menus.getOne", {
					slug,
					public_key: config.public_key,
					validatePermissions,
				})
				.then((resp) => {
					dynamicReducer.menus({
						fields: { ...resp, slug },
					});

					resolve(resp);
				})
				.catch((err) => {
					if (retries > 0) {
						resolve(
							this.menu(slug, validatePermissions, retries - 1),
						);
					} else {
						reject(err);
					}
				});
		});
	}

	backwardsFindMenu(slug) {
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("menus.backwardsFindMenu", {
					collectionSlug: slug,
					public_key: config.public_key,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	menuDetail(slug, retries = 2) {
		throw new Error("old code update backend to work");
		// this.callbacks["menu"] = callback
		// this.asteroid.subscribe("menu",{slug: slug });
		// if (!this.isServer) this.asteroid.subscribe("menu", {slug: slug })
		if (this.isServer) return;

		return new Promise((resolve, reject) => {
			this.asteroid
				.call("menus.getOneDetail", { slug })
				.then((resp) => {
					dynamicReducer.menus({
						fields: { ...resp, slug },
					});
					resolve(resp);
				})
				.catch((err) => {
					if (retries > 0) {
						resolve(this.menuDetail(slug, retries - 1));
					} else {
						reject(err);
					}
				});
		});
	}

	collection(slug, brandSlug, retries = 2) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("collections.get", {
					slug,
					brandSlug,
					public_key: config.public_key,
					lang: this.lang,
				})
				.then((resp) => {
					if (resp) {
						dynamicReducer.collections(resp);
						resolve(resp);
						return;
					}
					reject(resp);
				})
				.catch((err) => {
					if (retries > 0) {
						resolve(this.collection(slug, brandSlug, retries - 1));
					} else {
						reject(err);
					}
				});
		});
	}

	collectionChildren({ slug, limit, productSlugs }) {
		const self = this;
		dynamicReducer.childCollection([]);
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("collections.children", {
					slug,
					productSlugs,
					public_key: config.public_key,
					limit,
				})
				.then((resp) => {
					dynamicReducer.childCollection(resp);
					resolve(resp);
				})
				.catch((err) => {
					dynamicReducer.childCollection([]);
					reject(err);
				});
		});
	}

	page({ slug, lang, brandSlug }, retries = 2) {
		const self = this;

		return new Promise((resolve, reject) => {
			self.asteroid
				.call("pages.getOne", {
					slug,
					public_key: config.public_key,
					lang,
					brandSlug,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					if (retries > 0) {
						resolve(
							this.page({ slug, lang, brandSlug }, retries - 1),
						);
					} else {
						reject(err);
					}
				});
		});
	}

	post(slug) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("posts.getOne", {
					slug,
					public_key: config.public_key,
					lang: this.lang,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	postList({ limit, page }) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("posts.list", {
					public_key: config.public_key,
					limit,
					page,
					lang: this.lang,
				})
				.then((resp) => {
					dynamicReducer.posts({ fields: resp });
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	called = 0;

	productCollections(
		{ slug, currentPage, limit, tags, order, brandSlug },
		retries = 2,
	) {
		const self = this;

		return new Promise((resolve, reject) => {
			self.asteroid
				.call("products.getCollection", {
					slug,
					page: currentPage,
					limit: parseInt(limit),
					tags,
					order,
					public_key: config.public_key,
					lang: this.lang,
					brandSlug,
				})
				.then((resp) => {
					dynamicReducer.products({
						products: { ...resp },
						slug,
						page: currentPage,
					});
					resolve(resp);
				})
				.catch((err) => {
					if (retries > 0) {
						resolve(
							this.productCollections(
								{
									slug,
									currentPage,
									limit,
									tags,
									order,
									brandSlug,
								},
								retries - 1,
							),
						);
					} else {
						reject(err);
					}
				});
		});
	}

	productMaualCollections(
		{ slug, currentPage, limit, tags, order },
		retries = 2,
	) {
		const self = this;
		return new Promise((resolve, reject) => {
			if (this.isServer) reject("");
			self.asteroid
				.call("products.manualCollection", {
					slug,
					page: currentPage,
					limit: parseInt(limit),
					tags,
					order,
					public_key: config.public_key,
					lang: this.lang,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					if (retries > 0) {
						resolve(
							this.productMaualCollections(
								{
									slug,
									currentPage,
									limit,
									tags,
									order,
								},
								retries - 1,
							),
						);
					} else {
						reject(err);
					}
				});
		});
	}

	backWardFindCollectionProducts({ slug }) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("products.backWardFindCollectionProducts", {
					slug,
					public_key: config.public_key,
					lang: this.lang,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	collectionTags({ slug, tags }) {
		if (this.isServer) return;
		this.asteroid
			.call("products.collectionTags", {
				slug,
				public_key: config.public_key,
				tags,
				lang: this.lang,
			})
			.then((resp) => {
				dynamicReducer.tags({
					fields: { ...resp, slug },
				});
			})
			.catch((err) => {});
	}

	setLastKnownProduct(slug) {
		this.lastKnownProduct = slug;
	}

	getLastKnownProduct(slug) {
		return this.lastKnownProduct;
	}

	product({ slug, brandSlug }, retries = 2) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("products.get", {
					slug,
					brandSlug,
					lang: this.lang,
					public_key: config.public_key,
				})
				.then((resp) => {
					dynamicReducer.product({ fields: { ...resp } });
					resolve(resp);
				})
				.catch((err) => {
					if (retries > 0) {
						resolve(this.product({ slug }, retries - 1));
					} else {
						reject(err);
					}
				});
		});
	}

	mapToProduct = ({ product }) => {
		dynamicReducer.product({ fields: { ...product, slug: product.slug } });
	};

	nextProduct({ slug, order, collectionSlug, defaultTags, brandSlug }) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("products.next", {
					slug,
					order,
					collectionSlug,
					brandSlug,
					defaultTags,
					lang: this.lang,
					public_key: config.public_key,
				})
				.then((resp) => {
					dynamicReducer.product({ fields: { ...resp } });
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	backProduct({ slug, order, collectionSlug, defaultTags, brandSlug }) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("products.back", {
					slug,
					order,
					collectionSlug,
					defaultTags,
					brandSlug,
					lang: this.lang,
					public_key: config.public_key,
				})
				.then((resp) => {
					dynamicReducer.product({ fields: { ...resp } });
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	productSearch({ text, tags, limit, facetsTags, page, order, brandSlug }) {
		const self = this;

		return new Promise((resolve, reject) => {
			this.asteroid
				.call("products.esSearch2", {
					text,
					tags,
					facetsTags,
					lang: self.lang,
					limit: limit || 6,
					page,
					order,
					brandSlug,
					public_key: config.public_key,
				})
				.then((resp) => {
					dynamicReducer.search({ fields: resp });
					resolve();
				})
				.catch((err) => {
					dynamicReducer.cart({ fields: { products: [], tags: [] } });

					reject(err);
				});
		});
	}

	searchSugestion({ text, tags, limit }) {
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("search.sugestions", {
					tags,
					text,
					limit: limit || 6,
					public_key: config.public_key,
				})
				.then((resp) => {
					dynamicReducer.suggestions({ fields: resp });
					resolve(resp);
				})
				.catch((err) => {
					dynamicReducer.suggestions({ fields: [] });
					reject(err);
				});
		});
	}

	productAddToCart({ productId, quantity, cookie_token }, retries = 2) {
		const self = this;
		// const cookie_token = localStorage.getItem("ntx-cookie_token");
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("carts.insert", {
					productId,
					quantity,
					cookie_token,
					lang: this.lang,
					public_key: config.public_key,
					analyticsId: this.getAnalyticsId(),
				})
				.then((resp) => {
					this.updateStats(resp);
					// localStorage.setItem("ntx-cookie_token", resp.cookie_token);

					resolve(resp);
					// this.worker.postMessage({ do: "total", items: resp.items });
					// dynamicReducer.cart({ fields: resp });
				})
				.catch((err) => {
					if (retries > 0) {
						setTimeout(() => {
							resolve(
								this.productAddToCart(
									{ productId, quantity, cookie_token },
									retries - 1,
								),
							);
						}, 100 + 1000 * (2 - retries));
					} else {
						reject(err);
					}
				});
		});
	}

	productAddToCartMany({ productsToAdd }) {
		const self = this;
		// const cookie_token = localStorage.getItem("ntx-cookie_token");
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("carts.addMany", {
					productsToAdd,

					lang: this.lang,
					public_key: config.public_key,
					analyticsId: this.getAnalyticsId(),
				})
				.then((resp) => {
					// localStorage.setItem("ntx-cookie_token", resp.cookie_token);
					this.updateStats(resp);
					resolve(resp);

					// this.worker.postMessage({ do: "total", items: resp.items });
					// dynamicReducer.cart({ fields: resp });
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	addCommentsProductCart = ({ productId, comments }) => {
		const self = this;

		return new Promise((resolve, reject) => {
			self.asteroid
				.call("carts.addProductComment", {
					productId,
					comments,

					lang: this.lang,
					public_key: config.public_key,
				})
				.then((resp) => {
					resolve(resp);

					this.updateStats(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	removeFromCart({ productId, cookie_token }, retries = 2) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("carts.remove", {
					productId,
					lang: this.lang,
					cookie_token,
					public_key: config.public_key,
					analyticsId: this.getAnalyticsId(),
				})
				.then((resp) => {
					resolve("removed");
					this.updateStats(resp);
				})
				.catch((err) => {
					if (retries > 0) {
						setTimeout(() => {
							resolve(
								this.removeFromCart({ productId }, retries - 1),
							);
						}, 50 + 1000 * (2 - retries));
					} else {
						reject(err);
					}
				});
		});
	}

	getCart() {
		// const cookie_token = localStorage.getItem("ntx-cookie_token");
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("cart.cartsClient", {
					lang: this.lang,
					// cookie_token,
					public_key: config.public_key,
				})
				.then((resp) => {
					this.updateStats(resp);
					resolve(resp);
				})
				.catch((err) => {
					this.updateStats(null);
					resolve();
				});
		});
	}

	addTitleToCart({ title }) {
		const self = this;
		const cookie_token = localStorage.getItem("ntx-cookie_token");
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("cart.setTitle", {
					lang: this.lang,
					cookie_token,
					title,
					public_key: config.public_key,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					resolve();
				});
		});
	}

	getCartFromGroup({ tags }) {
		const self = this;

		return new Promise((resolve, reject) => {
			this.asteroid
				.call("cart.itemsFromGroup", {
					lang: this.lang,
					tags,
					public_key: config.public_key,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	getCartAndValidate() {
		// const cookie_token = localStorage.getItem("ntx-cookie_token");
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("carts.getAndValidateAll", {
					lang: this.lang,
					// cookie_token,
					public_key: config.public_key,
				})
				.then((resp) => {
					this.updateStats(resp);
					resolve(resp);
				})
				.catch((err) => {
					console.log(err);
					// this.updateStats(null);
					reject(err);
				});
		});
	}

	assignCartToUser() {
		const self = this;
		const cookie_token = localStorage.getItem("ntx-cookie_token");
		if (cookie_token == null) return;
		return new Promise((resolve) => {
			this.asteroid
				.call("carts.assignCart", {
					lang: this.lang,
					cookie_token,
					public_key: config.public_key,
				})
				.then((resp) => {
					this.updateStats(resp);
					resolve(resp);
				})
				.catch((err) => {
					resolve(null);
				});
		});
	}

	productView({ slug }) {
		this.asteroid
			.call("products.view", {
				slug,
				public_key: config.public_key,
				analyticsId: this.getAnalyticsId(),
			})
			.then((resp) => {})
			.catch((err) => {});
	}

	productClick({ slug, productId, searchQuery }) {
		this.asteroid
			.call("products.click", {
				slug,
				productId,
				searchQuery,
				public_key: config.public_key,
				analyticsId: this.getAnalyticsId(),
			})
			.then((resp) => {})
			.catch((err) => {});
	}

	itemToItem({ slug, logic, limit, scenario, resetSimilar }) {
		return new Promise((resolve, reject) => {
			if (this.isServer) reject();

			this.asteroid
				.call("products.itemToItem", {
					slug,
					lang: this.lang,
					logic,
					limit,
					scenario,
					public_key: config.public_key,
					analyticsId: this.getAnalyticsId(),
				})
				.then((resp) => {
					dynamicReducer.itemToItem({
						fields: { products: [...resp] },
					});
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	itemToItemPurchasing({ slug, logic, limit }) {
		if (this.isServer) return;
		dynamicReducer.crossSell({
			fields: { products: [...resp] },
		});
		this.asteroid
			.call("products.itemToItem", {
				slug,
				lang: this.lang,
				logic,
				limit,
				public_key: config.public_key,
				analyticsId: this.getAnalyticsId(),
			})
			.then((resp) => {
				dynamicReducer.crossSell({
					fields: { products: [...resp] },
				});
			})
			.catch((err) => {});
	}

	itemToUser({ scenario, limit, logic, filter, rotationRate }) {
		dynamicReducer.recomendations({
			fields: { products: [] },
		});
		return new Promise((resolve, reject) => {
			if (this.isServer) reject();
			this.asteroid
				.call("products.itemsToUser", {
					scenario,
					limit,
					filter,
					lang: this.lang,
					rotationRate,
					logic,
					public_key: config.public_key,
					analyticsId: this.getAnalyticsId(),
				})
				.then((resp) => {
					resolve(resp);
					dynamicReducer.recomendations({
						fields: { products: [...resp] },
					});
				})
				.catch((err) => {
					reject();
				});
		});
	}

	homePage({
		scenario,
		limit,
		logic,
		filter,
		rotationRate,
		limitPerRow,
		type,
		tag,
		page,
		includeBestsellers,
		includePersonal,
		recentlyViewed,
	}) {
		return new Promise((resolve, reject) => {
			if (this.isServer) reject();
			this.asteroid
				.call("products.homePage", {
					scenario,
					limit,
					filter,
					lang: this.lang,
					rotationRate,
					limitPerRow,
					logic,
					page,
					type,
					public_key: config.public_key,
					analyticsId: this.getAnalyticsId(),
					includeBestsellers,
					includePersonal,
					recentlyViewed,
				})
				.then((resp) => {
					resolve(resp);
					dynamicReducer.personalizedExperience({
						fields: resp,
						tag: tag ? tag : " ",
						type,
					});
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	formInsert({ fields, form_key }) {
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("forms.insertPublic", {
					fields,
					form_key,
					lang: this.lang,
				})
				.then((resp) => {
					resolve("inserted");
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	claimForm({ fields, form_key, brandSlug, claimId }) {
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("claims.insert", {
					fields,
					form_key,
					brandSlug,
					lang: this.lang,
					public_key: config.public_key,
					upated_id: claimId,
				})
				.then((resp) => {
					if (resp.isCustomError) resolve(resp);
					resolve("inserted");
				})
				.catch((err) => {
					resolve(err);
				});
		});
	}

	salesreps({ brandSlug }) {
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("salesreps.list", {
					public_key: config.public_key,
					brandSlug,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					resolve(err);
				});
		});
	}

	salesrep({ slug }) {
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("salesreps.getBySlug", {
					slug,
					public_key: config.public_key,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					resolve(err);
				});
		});
	}

	signedUrlUpload(meta) {
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("forms.signedUrl", meta)
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					resolve(err);
				});
		});
	}

	generateProforma = ({ orderId, wishlistId, cartIds }) => {
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("generateProforma", {
					orderId,
					wishlistId,
					lang: this.lang,
					cartIds,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	generateProformaFromIntegration = ({
		documentNumber,
		companyId,
		typeOfRequest,
	}) => {
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("users.getProformaUrlFromIntegration", {
					documentNumber,
					lang: this.lang,
					companyId,
					typeOfRequest,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	requestOrderImages = ({ orderId }) => {
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("orders.requestImages", { orderId })
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					resolve(err);
				});
		});
	};

	requestOrderData = ({ orderId, templateModel }) => {
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("orders.requestOrderData", {
					orderId,
					templateModel,
					lang: this.lang,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	setPriceLevel = (clientNumber) => {
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("users.setClientDiscount", clientNumber)
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	profileUpdate = (profile) => {
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("users.profileUpdate", profile)
				.then((user) => {
					dynamicReducer.user({
						fields: { ...user, id: user._id },
					});
					resolve(user);
				})
				.catch((err) => {
					console.log("err", err);
					resolve(err);
				});
		});
	};

	storeUpdate = ({ clientMarketSegment, companyName }) => {
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("users.storeInformation", {
					clientMarketSegment,
					companyName,
				})
				.then((user) => {
					dynamicReducer.user({
						fields: { ...user, id: user._id },
					});
					resolve(user);
				})
				.catch((err) => {
					resolve(err);
				});
		});
	};

	storePriceConfiguration({ multiplicador, conversion, currency }) {
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("users.storePrice", {
					multiplicador,
					conversion,
					currency,
				})
				.then((user) => {
					dynamicReducer.user({
						fields: { ...user, id: user._id },
					});
					resolve(user);
				})
				.catch((err) => {
					resolve(err);
				});
		});
	}

	requestStatement = ({ email, companyId }) => {
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("integrations.statement", {
					email,
					companyId,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					resolve(err);
				});
		});
	};

	validateReports = ({ type, name }) => {
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("integrations.validateReports", {
					type,
					name,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					resolve(err);
				});
		});
	};

	accountState = ({ publicKey }) => {
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("contacts.getAccountState", {
					publicKey,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	/**
	 * Cancel order
	 * @param {number} page - page number. Default 1
	 * @param {number} perPage - item number per page. Default 10
	 * @return {Promise<Object>} - Promise returning orders list
	 */
	searchOrders({ page, perPage, searchText, paymentFilters }) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("orders.search", {
					searchText,
					page,
					perPage,
					paymentFilters,
					isClient: true,
				})
				.then((resp) => {
					// dynamicReducer.orders({
					//     fields: resp,
					//     page,
					// });
					resolve(resp);
				})
				.catch((err) => {
					console.log("errors with search", err);
					reject(err);
				});
		});
	}

	OrderById({ id }) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("orders.byId", { id })
				.then((resp) => {
					// console.log(resp);
					dynamicReducer.order({
						fields: resp,
						id,
					});
					resolve(resp);
				})
				.catch((err) => {
					console.log("errors with search", err);
					reject(err);
				});
		});
	}

	/**
	 * Get products by text
	 * @param {string} text - Value entered search input.
	 * @param {string} orderId - Order id.
	 * @return {Promise<Object>} - Promise returning an object with products details.
	 */
	searchOrderProducts({ text, orderId, includeManualCollection }) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("products.getForOrder", {
					text,
					orderId,
					includeManualCollection,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
					console.log("errors with product");
				});
		});
	}

	/**
	 * Get Shipping Configuration
	 * @return {Promise<Object>} - Promise returning shipping countries and skipShipping skipPayment showTaxCheckout
	 */
	shippingConfiguration() {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("company.checkoutOptions", {
					public_key: config.public_key,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					console.log("errors with shipping", err);
					reject(err);
				});
		});
	}

	/**
	 * Create a new order from shipping address
	 * @param {object} order - object containing first name, last name, address1, address2, country, region, city contactPhoneNumber email and postalCode.
	 * @param {string} order.firstName - firstName shipping address
	 * @param {string} order.lastName - lastName shipping address
	 * @param {string} order.address1 - address1 shipping address
	 * @param {string} order.address2 - address2 shipping address
	 * @param {string} order.country - country shipping address
	 * @param {string} order.region - region shipping address
	 * @param {string} order.city - city shipping address
	 * @param {string} order.contactPhoneNumber - contactPhoneNumber shipping address
	 * @param {string} order.email - email shipping address
	 * @param {string} order.postalCode - postalCode shipping address (optional)
	 * @return {Promise<Object>} - Promise returning the public_token for retriving order. This token should be saved to retrieve order.
	 */
	orderShipping({ fields, quotation, cartIds }) {
		const self = this;

		return new Promise((resolve, reject) => {
			self.asteroid
				.call("orders.shippingAll", {
					fields,
					cartIds,
					quotation,
					processClosing: "Closed from front end",
					public_key: config.public_key,
				})
				.then((resp) => {
					self.getCart();
					resolve(resp);
				})
				.catch((err) => {
					console.log("errors with shipping", err);
					reject(err);
				});
		});
	}

	/**
	 * get Order by token
	 * @param {object} order - object containing public_token
	 * @param {string} order.public_token - public_token of order
	 * @return {Promise<Object>} - Promise returning the order
	 */
	orderByToken({ public_tokens }) {
		const self = this;
		const cookie_token = localStorage.getItem("ntx-cookie_token");
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("orders.getOrdersByToken", {
					public_tokens,
					public_key: config.public_key,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					console.log("errors with shipping", err);
					reject(err);
				});
		});
	}

	/**
	 * Get wish lists
	 * @return {Promise<Object>} - Promise returning.
	 */
	getWishlists() {
		const self = this;

		return new Promise((resolve, reject) => {
			self.asteroid
				.call("whishlists.get", { lang: self.lang })
				.then((resp) => {
					dynamicReducer.wishlists({ fields: resp });
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
					console.log("errors with get wish lists");
				});
		});
	}
	/**
     * Get wish list products.
     * @param {string} wishlistId - Wish list id.
     * @param {string} order - Type of sort.
     
     * @param {string} slug - (Optional) Slug collection.
     * @param {string} page - (Optional) Page number for pagination.
     * @param {string} limit - (Optional) Limit for pagination.
     * @return {Promise<Object>} - Promise returning.
     */
	detailsWishlist({ wishlistId, order, slug, page, limit }, retries = 4) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("whishlists.details", {
					wishlistId,
					order,
					lang: self.lang,
					slug,
					page,
					limit,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					if (retries > 0) {
						setTimeout(() => {
							resolve(
								this.detailsWishlist(
									{ wishlistId, order, slug, page, limit },
									retries - 1,
								),
							);
						}, 100 + 1000 * (4 - retries));
					} else {
						reject(err);
					}
				});
		});
	}

	/**
	 * Upsert wish list.
	 * @param {Object} wishlist - Wish list object. Fields: title, products (array with product ids).
	 * @return {Promise<Object>} - Promise returning.
	 */
	upsertWishlist({ wishlist }) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("whishlists.upsert", { wishlist })
				.then((resp) => {
					self.getWishlists();
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
					console.log("errors with upsert wish list");
				});
		});
	}
	/**
	 * Insert wish list product.
	 * @param {string} wishlistId - Wish list id.
	 * @param {string} productId - Product id.
	 * @return {Promise<Object>} - Promise returning.
	 */
	insertProductWishlist({ wishlistId, productId }) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("whishlists.products.insert", { wishlistId, productId })
				.then((resp) => {
					self.getWishlists();
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
					console.log("errors with insert product wish list");
				});
		});
	}
	/**
	 * Remove wish list product.
	 * @param {string} wishlistId - Wish list id.
	 * @param {string} productId - Product id.
	 * @return {Promise<Object>} - Promise returning.
	 */
	removeProductWishlist({ wishlistId, productId }) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("whishlists.products.remove", { wishlistId, productId })
				.then((resp) => {
					self.getWishlists();
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
					console.log("errors with remove product wish list");
				});
		});
	}
	/**
	 * Remove wish list.
	 * @param {string} wishlistId - Wish list id.
	 * @return {Promise<Object>} - Promise returning.
	 */
	removeWishlist({ wishlistId }) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("whishlists.remove", { wishlistId })
				.then((resp) => {
					self.getWishlists();
					resolve(resp);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
					console.log("errors with remove wish list");
				});
		});
	}
	/**
	 * Invite wish list.
	 * @param {string} wishlistId - Wish list id.
	 * @param {string} email - Contact email.
	 * @return {Promise<Object>} - Promise returning.
	 */
	inviteWishlist({ wishlistId, email }, retries = 4) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("whishlists.invitation", { wishlistId, email })
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					if (retries > 0) {
						setTimeout(() => {
							resolve(
								this.inviteWishlist(
									{ wishlistId, email },
									retries - 1,
								),
							);
						}, 100 + 1000 * (4 - retries));
					} else {
						reject(err);
					}
					console.log("errors with invitation wish list");
				});
		});
	}
	/**
	 * Validate invitation wish list.
	 * @param {string} public_token - Public token wish list.
	 * @return {Promise<Object>} - Promise returning.
	 */
	validateInvitation({ public_token }) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("whishlists.validateInvitation", { public_token })
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
					console.log("errors with invitation wish list");
				});
		});
	}
	/**
	 * Accept invitation wish list.
	 * @param {string} public_token - Public token wish list.
	 * @return {Promise<Object>} - Promise returning.
	 */
	acceptInvitation({ public_token, accepted }) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("whishlists.acceptInvitation", { public_token, accepted })
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
					console.log("errors with accept invitation wish list");
				});
		});
	}
	/**
	 * Remove invitation wish list.
	 * @param {string} wishlistId - Wish list id.
	 * @param {string} userId - (optional) Contact user id. Default, current user.
	 * @return {Promise<Object>} - Promise returning.
	 */
	removeInvitation({ wishlistId, userId }) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("whishlists.removeInvitation", { wishlistId, userId })
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
					console.log("errors with remove invitation wish list");
				});
		});
	}

	emptyNotAvailableProducts({ cartIds }) {
		const self = this;
		const cookie_token = localStorage.getItem("ntx-cookie_token");
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("carts.emptyNotAvailable", {
					lang: this.lang,
					cookie_token,
					cartIds,
					public_key: config.public_key,
				})
				.then((resp) => {
					this.updateStats(resp);
					resolve(resp);
				})
				.catch((err) => {
					resolve();
				});
		});
	}

	emptyCart({ cartIds }) {
		const self = this;
		const cookie_token = localStorage.getItem("ntx-cookie_token");
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("carts.emptyCart", {
					lang: this.lang,
					cartIds,
					public_key: config.public_key,
				})
				.then((resp) => {
					this.updateStats(resp);
					resolve(resp);
				})
				.catch((err) => {
					dynamicReducer.cart({ fields: null });
					self.worker.postMessage({ do: "total", items: [] });
					resolve();
				});
		});
	}

	cartProducts() {
		const cookie_token = localStorage.getItem("ntx-cookie_token");
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("products.getFromAllCarts", {
					lang: this.lang,
					cookie_token,
					public_key: config.public_key,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
	}

	cartGroups({ globalFilters }) {
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("cart.groups", {
					lang: this.lang,
					tags: globalFilters,
					public_key: config.public_key,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	/**
	 * Get product association.
	 * @param {String} slug - Product slug.
	 * @param {String} lang - (optional) Language.
	 * @return {Promise<Object>} - Promise returning product association.
	 */
	productAssociations({ slug }) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("prodAssociations.get", { slug, lang: this.lang })
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	didTutorial() {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("user.didTutorial", { public_key: config.public_key })
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	didOnboarding({ answers }) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("user.didOnboarding", {
					public_key: config.public_key,
					answers,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	faqs({ featured, query }) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("faqs.get", {
					public_key: config.public_key,
					featured,
					search: query,
					lang: this.lang,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	createReferral(formData) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("referrals.insert", {
					public_key: config.public_key,
					...formData,
					lang: this.lang,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	getUserReferrals() {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("referrals.userPending", {
					public_key: config.public_key,
					lang: this.lang,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	questions({ productId, limit, page }) {
		return this.asteroid.call("questions.products", {
			public_key: config.public_key,
			lang: this.lang,
			productId,
			limit,
			page,
		});
	}

	requestProductData({ ask, productId, question }) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("requests.productData", {
					public_key: config.public_key,
					lang: this.lang,
					ask,
					productId,
					question,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	/**
	 * Get payment settings from profile
	 * @return {Promise<Object>} - Promise returning an object with user profile.
	 */
	profileForPayment() {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("profiles.getForPayment")
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	checkTerms(retries = 2) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("terms.check")
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					if (retries > 0) {
						setTimeout(() => {
							resolve(this.checkTerms(retries - 1));
						}, 100 + 1000 * (2 - retries));
					} else {
						reject(err);
					}
				});
		});
	}

	acceptTerms({ acceptedTerms, acceptedCookies }) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("terms.accept", { acceptedTerms, acceptedCookies })
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	/**
	 * Get company settings
	 * @return {Promise<Object>} - Promise returning company settings.
	 */
	getCompanySettings(retries = 2) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("company.getSettings", { public_key: config.public_key })
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					if (retries > 0) {
						setTimeout(() => {
							resolve(this.getCompanySettings(retries - 1));
						}, 100 + 1000 * (2 - retries));
					} else {
						reject(err);
					}
				});
		});
	}

	generateLinkForPDF({ lang, user, wishlistId }) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("links.generatePdf", {
					public_key: config.public_key,
					lang,
					user,
					wishlistId,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
					console.log("errors with get company settings");
				});
		});
	}

	getLinksForPdf({ wishlistId }) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("links.pdfLinks", {
					public_key: config.public_key,
					wishlistId,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	getTrendingCategories({ type, trendingOnly, limit }, retries = 2) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("orders.trendingCategories", {
					public_key: config.public_key,
					type,
					trendingOnly,
					limit,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					if (retries > 0) {
						resolve(
							self.getTrendingCategories(
								{ type, trendingOnly, limit },
								retries - 1,
							),
						);
					} else {
						reject(err);
					}
				});
		});
	}

	getCartRestrictions() {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("restrictions.getCartRestrictions")
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	consolidationSlug({ slug }) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("consolidations.slug", { slug })
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	consolidationCart() {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("consolidations.cart")
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	dimissNotification({ udid }) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("pushNotifications.remove", { udid })
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	productsValidate({ slugs }) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("products.validate", {
					slugs,
					public_key: config.public_key,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	/**
	 * get shipping methods available for order
	 * @param {object} { public_token } - object containing public_token from orderShipping method
	 * @return {Promise<Object>} - Promise returning the array with shipping methods
	 */
	shippingMethods({ public_token }) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("shipping.methods", {
					public_token,
					public_key: config.public_key,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					console.log("errors with shipping", err);
					reject("reject");
				});
		});
	}

	/**
	 * Set order shipping rate
	 * @param {object} { shippingId, rate, public_tokenn } - object containing  shippingId, rate and public_token from orderShipping method
	 * @return {Promise<Object>} - Promise returning Object with order
	 */
	orderShippingRate({ shippingId, rate, public_token, forseCloseCart }) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("orders.setShippingMethod", {
					shippingId,
					rate,
					public_token,
					forseCloseCart,
					public_key: config.public_key,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					console.log("errors with shipping", err);
					reject(err);
				});
		});
	}

	brandCollections({ slug, page, limit }) {
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("brandCollections.get", {
					slug,
					page,
					limit,
				})
				.then((resp) => {
					dynamicReducer.brands({
						products: resp,
						slug,
						page,
					});

					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	brand({
		slug,
		includeProducts,
		limit,
		page,
		includeMenus,
		includeBrandAccess,
	}) {
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("brands.getOne", {
					slug,
					includeMenus,
					includeProducts,
					includeBrandAccess,
					limit,
					page,
					lang: this.lang,
				})
				.then((resp) => {
					dynamicReducer.brand({
						...resp,
					});

					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	insertBrandRequest({ brandSlug, brandId }) {
		if (typeof analytics !== "undefined") {
			analytics.track("Request Brand Access", { brandSlug, brandId });
		}
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("brandRequests.insert", {
					brandId,
					brandSlug,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	myBrands({ limit }) {
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("brands.user", { limit })
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	claimText({ brandSlug }) {
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("company.getClaimFormText", {
					public_key: config.public_key,
					lang: this.lang,
					brandSlug,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	getMedias({ limit, page, queryString, brandId, filter, categories }) {
		return this.asteroid.call("mediaForSharing.getClient", {
			limit,
			page,
			queryString,
			brandId,
			filter,
			categories,
		});
	}

	credit({
		companyId,
		typeOfRequest,
		documentNumber,
		includeProducts,
		page,
		limit,
	}) {
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("users.getCreditForClient", {
					companyId,
					documentNumber,
					includeProducts,
					lang: this.lang,
					typeOfRequest,
					page,
					limit,
				})
				.then((resp) => {
					resolve(resp);
					if (resp)
						dynamicReducer.accountCredit({
							fields: { ...resp, companyId, byPassLoading: true },
						});
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	orderFromERP({
		companyId,
		typeOfRequest,
		documentNumber,
		includeProducts,
		page,
		limit,
	}) {
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("users.getCreditForClient", {
					companyId,
					documentNumber,
					includeProducts,
					lang: this.lang,
					typeOfRequest,
					page,
					limit,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	getClaimsFromUser(options) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("claims.getAllByUserId", {
					...options,
					public_key: config.public_key,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	getClaimDetailsFromUserToEdit(options) {
		const self = this;
		const { claimId, brandSlug } = options;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("claims.getClaimDetails", {
					claimId,
					public_key: config.public_key,
					brandSlug,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	getCanceledProdsOrder(options) {
		const self = this;
		const { public_key, lang } = options;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("canceledProducts.getCanceledProdsOrder", {
					publicKey: public_key,
					lang,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	setCanceledProdReplace(options) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("canceledProducts.setCanceledProdReplace", options)
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	setCanceledProdConfirm(options) {
		const self = this;
		return new Promise((resolve, reject) => {
			self.asteroid
				.call("canceledProducts.setCanceledProdConfirm", options)
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	getCompanyPastdueBills() {
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("company.getCompanyPastdueBills", {
					public_key: config.public_key,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	setUserUnsuscribe({ pub, s }) {
		return new Promise((resolve, reject) => {
			this.asteroid
				.call("users.setUnsuscribe", {
					public_key: config.public_key,
					pub,
					s,
				})
				.then((resp) => {
					resolve(resp);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
}
//
// for (var i = products.length - 1; i >= 0; i--) {
//  Blueberry.productAddToCart({productId: products[i], quantity: 1})
// }

// for (var i = 0; i <= products.length; i++) {
//  Blueberry.productAddToCart({productId: products[i], quantity: 1})
// }

// Connect to a Meteor backend
// let asteroid = null

// if (typeof window !== 'undefined') {

// }
// if you want realitme updates in all connected clients
// subscribe to the publication

const Blueberry = new blue(typeof window === "undefined");
// console.log("WARNING-----------------------blueberry is exposed----------------------------")
// console.log("WARNING-----------------------blueberry is exposed----------------------------")
// console.log("WARNING-----------------------blueberry is exposed----------------------------")
// console.log("WARNING-----------------------blueberry is exposed----------------------------")
// console.log("WARNING-----------------------blueberry is exposed----------------------------")
// console.log("WARNING-----------------------blueberry is exposed----------------------------")
// console.log("WARNING-----------------------blueberry is exposed----------------------------")
// console.log("WARNING-----------------------blueberry is exposed----------------------------")
// if (typeof window !== 'undefined') window.Blueberry = Blueberry
export default Blueberry;
